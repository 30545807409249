<nav  *ngIf="idEmpresa!=78" class="navbar navbar-expand-lg  shadow-sm fixed-top" id="viewLG">
  <div class="container-fluid">
    <a class="navbar-brand" >
      <img  class="imgNavbar" [src]="urlBase+configuracion?.imgLogo+'?alt=media'" style="margin-left: 0.5em; ">
    </a>
    <button (click)="seeExpand()" class="navbar-toggler btn btn-sm" type="button" class="navbar-toggler" type="button"  aria-controls="navbarSupportedContent">
      <span class="navbar-toggler-icon">
        <i class="bi bi-list"></i>

      </span>
    </button>
    <div class="collapse navbar-collapse "   [ngClass]="{'show': !isNavbarCollapsed}" id="navbarSupportedContent1" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto" style="display: flex; justify-content: center; text-align: center; " >
        <li class="nav-item">
          <a *ngIf=" configuracion.verInicio==1" class="nav-link" (click)="redirecTO('nosotros')" [routerLink]="['/inicio']" routerLinkActive="router-link-active" >Sobre Nosotros</a>
        </li>
        <li class="nav-item">
          <a *ngIf="idEmpresa!=70 && configuracion.verHome==1" class="nav-link"   [routerLink]="['/home/1']" routerLinkActive="router-link-active">E-commerce</a>
          <a *ngIf="idEmpresa==70  && configuracion.verHome==1" class="nav-link"   [routerLink]="['/home/1']" routerLinkActive="router-link-active">Nuestros Servicios</a>
        </li>
        <li class="nav-item">
          <a  *ngIf=" configuracion.verCatalogo==1" class="nav-link"   [routerLink]="['/catalogo']" routerLinkActive="router-link-active">Catálogo</a>
        </li>
        <li style="margin-left: 2.5em !important;" >
          <div class="bg-light rounded rounded-pill shadow-sm  divSearch pt-0"  >
              <form  class=" " style="padding: 0; margin: 0; " [formGroup]="formSearchProduct">
                <div class="input-group mt-0">
                  <div class="input-group-prepend">
                      <button id="button-addon2" type="submit" class="btn btn-link text-secondary" (click)="searchProduct(formSearchProduct.value)">
                        <i class="fa fa-search"></i>
                      </button>
                  </div>
                  <input type="text" formControlName="text"  placeholder="¿Qué está buscando?"
                      aria-describedby="button-addon2" class="form-control border-0 bg-white rounded-pill">
              </div>
              </form>
          </div>
        </li>

      
      </ul>
      <ul class="navbar-nav mr-rigth" *ngIf="information?.logo_dos"> 
        <li>
          <a class="navbar-brand" target="_blank" [href]="information.redirect_logo_dos"  >
            <img  class="imgNavbar " [src]="urlBase+information?.logo_dos+'?alt=media'" style="margin-right: 0.5em; border-radius: 12px; ">
          </a>
        </li>
      </ul>
      <!-- <ul class="navbar-nav mr-auto " style="padding: 0; margin: 0;">
        <li>
          <div class="bg-light rounded rounded-pill shadow-sm  divSearch pt-0"  >
              <form  class=" " style="padding: 0; margin: 0; " [formGroup]="formSearchProduct">
                <div class="input-group mt-0">
                  <div class="input-group-prepend">
                      <button id="button-addon2" type="submit" class="btn btn-link text-secondary" (click)="searchProduct(formSearchProduct.value)">
                        <i class="fa fa-search"></i>
                      </button>
                  </div>
                  <input type="text" formControlName="text"  placeholder="¿Qué está buscando?"
                      aria-describedby="button-addon2" class="form-control border-0 bg-white rounded-pill">
              </div>
              </form>
          </div>
        </li>
      </ul> -->

      <form class="form-inline my-2 my-lg-0" *ngIf="!flagCarLocal" >
        <div style="display: flex; justify-content: space-between; " >

          <div class="pt-1 divLogin " type="button" (click)="openModal(modalLogin)"  *ngIf="!clientLogin.login">
            <a class="nav-link"  >Iniciar Sesión</a>
          </div>
          <div class="div-auth pt-2" *ngIf="clientLogin.login"  >
            <i class="bi bi-box-arrow-right icoLogout"  type="button" title="SALIR" (click)="signOff()" ></i> 
            <span class="pr-3 linea-right-blanca spn-user-login fw-semibold" role="button" (click)="goClientProfile()">
               {{clientLogin.name | titlecase }}
            </span>
        </div>
          <div class="d-flex" style="height: 40px; background-color:rgb(133, 132, 132);  ">
            <div class="vr" style="width: 1px;"> </div>         
        </div>
        <ng-container *ngIf="information.esPuntoVenta==0" >
          <div class="btn mt-1 ml-1 mr-3 bgCarrito  " style="position: relative; "  type="button"  (click)="goShoppingCartUser()">
            <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;  "  class="badge badge-pill badge-danger">{{counterCar}}</span>
           Mi pedido <i class="bi bi-cart-fill p-1" style=" margin-top: 1em !important; " > </i>  
          </div>
        </ng-container>
        <ng-container  *ngIf="information.esPuntoVenta==1"  >
          <div class="btn mt-1 ml-1 mr-3 bgCarrito2  " style="position: relative; "  type="button"  (click)="goShoppingCartUser()">
            <span style="position: absolute; top:0 ; right: 0; font-size: 10px;  background-color: white; color: black;  "  class="badge badge-pill fw-bold">{{counterCar}}</span>
           Mi pedido <i class="bi bi-cart-fill p-1" style=" margin-top: 1em !important; " > </i>  
          </div>
        </ng-container>

        </div> 
      </form>
    </div>
  </div>
</nav> 

<nav  *ngIf="idEmpresa==78" class="navbar navbar-expand-lg  shadow-sm fixed-top" id="viewLG">
  <div class="container-fluid">
    <a class="navbar-brand" >
      <img  class="imgNavbar" [src]="urlBase+configuracion?.imgLogo+'?alt=media'" style="margin-left: 0.5em; ">
    </a>
    <button (click)="seeExpand()" class="navbar-toggler" type="button" class="navbar-toggler" type="button"  aria-controls="navbarSupportedContent">
      <span class="navbar-toggler-icon">
        <i class="bi bi-list"></i>
      </span>
    </button>
    <div class="collapse navbar-collapse "   [ngClass]="{'show': !isNavbarCollapsed}" id="navbarSupportedContent1" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto" style="display: flex; justify-content: center; text-align: center; " >
        <li class="nav-item">
          <a *ngIf=" configuracion.verInicio==1" class="nav-link" (click)="redirecTO('nosotros')" [routerLink]="['/inicio']" routerLinkActive="router-link-active" >Sobre Nosotros</a>
        </li>
        <li class="nav-item">
          <a  class="nav-link"   [routerLink]="['/home/1']" routerLinkActive="router-link-active">E-commerce</a>
        </li>
        <li class="nav-item">
          <a  *ngIf=" configuracion.verCatalogo==1" class="nav-link"   [routerLink]="['/catalogo']" routerLinkActive="router-link-active">Catálogo</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="router-link-active" (click)="redirect('mision')" [routerLink]="['/inicio']" routerLinkActive="router-link-active"  >Misión</a>
        </li>
        <li class="nav-item">
          <a  class="nav-link" routerLinkActive="router-link-active" (click)="redirect('vision')" [routerLink]="['/inicio']" routerLinkActive="router-link-active"  >Visión</a>
        </li>
        <li style="margin-left: 2.5em !important;" >
          <div class="bg-light rounded rounded-pill shadow-sm  divSearch pt-0"  >
              <form  class=" " style="padding: 0; margin: 0; " [formGroup]="formSearchProduct">
                <div class="input-group mt-0">
                  <div class="input-group-prepend">
                      <button id="button-addon2" type="submit" class="btn btn-link text-secondary" (click)="searchProduct(formSearchProduct.value)">
                        <i class="fa fa-search"></i>
                      </button>
                  </div>
                  <input type="text" formControlName="text"  placeholder="¿Qué está buscando?"
                      aria-describedby="button-addon2" class="form-control border-0 bg-white rounded-pill">
              </div>
              </form>
          </div>
        </li>

      
      </ul>
      <ul class="navbar-nav mr-rigth" *ngIf="information?.logo_dos"> 
        <li>
          <a class="navbar-brand" target="_blank" [href]="information.redirect_logo_dos"  >
            <img  class="imgNavbar " [src]="urlBase+information?.logo_dos+'?alt=media'" style="margin-right: 0.5em; border-radius: 12px; ">
          </a>
        </li>
      </ul>
      <!-- <ul class="navbar-nav mr-auto " style="padding: 0; margin: 0;">
        <li>
          <div class="bg-light rounded rounded-pill shadow-sm  divSearch pt-0"  >
              <form  class=" " style="padding: 0; margin: 0; " [formGroup]="formSearchProduct">
                <div class="input-group mt-0">
                  <div class="input-group-prepend">
                      <button id="button-addon2" type="submit" class="btn btn-link text-secondary" (click)="searchProduct(formSearchProduct.value)">
                        <i class="fa fa-search"></i>
                      </button>
                  </div>
                  <input type="text" formControlName="text"  placeholder="¿Qué está buscando?"
                      aria-describedby="button-addon2" class="form-control border-0 bg-white rounded-pill">
              </div>
              </form>
          </div>
        </li>
      </ul> -->

      <form class="form-inline my-2 my-lg-0" *ngIf="!flagCarLocal" >
        <div style="display: flex; justify-content: space-between; " >

          <div class="pt-1 divLogin " type="button" (click)="openModal(modalLogin)"  *ngIf="!clientLogin.login">
            <a class="nav-link"  >Iniciar Sesión</a>
          </div>
          <div class="div-auth pt-2" *ngIf="clientLogin.login"  >
            <i class="bi bi-box-arrow-right icoLogout"  type="button" title="SALIR" (click)="signOff()" ></i> 
            <span class="pr-3 linea-right-blanca spn-user-login fw-semibold" role="button" (click)="goClientProfile()">
               {{clientLogin.name | titlecase }}
            </span>
        </div>
          <div class="d-flex" style="height: 40px; background-color:rgb(133, 132, 132);  ">
            <div class="vr" style="width: 1px;"> </div>         
        </div>
        <ng-container *ngIf="information.esPuntoVenta==0" >
          <div class="btn mt-1 ml-1 mr-3 bgCarrito  " style="position: relative; "  type="button"  (click)="goShoppingCartUser()">
            <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;  "  class="badge badge-pill badge-danger">{{counterCar}}</span>
           Mi pedido <i class="bi bi-cart-fill p-1" style=" margin-top: 1em !important; " > </i>  
          </div>
        </ng-container>
        <ng-container  *ngIf="information.esPuntoVenta==1"  >
          <div class="btn mt-1 ml-1 mr-3 bgCarrito2  " style="position: relative; "  type="button"  (click)="goShoppingCartUser()">
            <span style="position: absolute; top:0 ; right: 0; font-size: 10px;  background-color: white; color: black;  "  class="badge badge-pill fw-bold">{{counterCar}}</span>
           Mi pedido <i class="bi bi-cart-fill p-1" style=" margin-top: 1em !important; " > </i>  
          </div>
        </ng-container>

        </div> 
      </form>
    </div>
  </div>
</nav> 

<nav *ngIf="idEmpresa!=78" class="navbar navbar-expand-lg shadow-sm fixed-top mb-5" id="viewSM">
  <div class="container-fluid">
    <form class="form-inline" style="margin-left: 0.5em;" *ngIf="!flagCarLocal">
      <div style="display: flex; justify-content: space-between;">

        <div class="pt-1 divLogin" type="button" (click)="openModal(modalLogin)" *ngIf="!clientLogin.login">
          <a class="nav-link">Iniciar Sesión</a>
        </div>
        <div class="div-auth pt-1" *ngIf="clientLogin.login">
          <i class="bi bi-box-arrow-right icoLogout" type="button" title="SALIR" (click)="signOff()"></i>
          <span class="pr-3 linea-right-blanca spn-user-login" role="button" (click)="goClientProfile()">
            {{clientLogin.name | titlecase}}
          </span>
        </div>
        <!-- <div class="d-flex" style="height: 50px; background-color:rgb(133, 132, 132);">
          <div class="vr" style="width: 1px;"></div>
        </div> -->
        <div class="btn mt-1 ml-1 mr-3 bgCarrito  " style="position: relative; "  type="button"  (click)="goShoppingCartUser()">
          <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;  "  class="badge badge-pill badge-danger">{{counterCar}}</span>
         <i class="bi bi-cart-fill p-1" style=" margin-top: 1em !important; " > </i>  
        </div>
        <!-- <div class="btn mt-1 mr-3" style="position: relative;" type="button" (click)="goShoppingCartUser()">
          <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;" class="badge badge-pill badge-danger">{{cartProducts.number}}</span>
          <i class="fas fa-shopping-cart" [ngStyle]="{ 'color': configuracion?.colorPrincipal }" style="width:6; height:6;"></i>
        </div> -->
      </div>
      
    </form>
    <button (click)="seeExpand()" class="navbar-toggler btn btn-sm" type="button"  aria-controls="navbarSupportedContent">   
      <span class="navbar-toggler-icon " style="color: black;" >
        <i class="bi bi-list"></i>
      </span>
    </button>
    <div class=" bg-light rounded rounded-pill shadow-sm  "  style="width: 100%;" >
      <form  class="flex-form " [formGroup]="formSearchProduct">
        <div class="input-group mt-0">
          <div class="input-group-prepend">
              <button id="button-addon2" type="submit" class="btn btn-link text-secondary" (click)="searchProduct(formSearchProduct.value)">
                <i class="fa fa-search"></i>
              </button>
          </div>
          <input type="text" formControlName="text"  placeholder="¿Qué está buscando?"
              aria-describedby="button-addon2" class="form-control border-0 bg-white rounded-pill">
      </div>
      </form>
    </div>
    <div class="collapse navbar-collapse"   
    [ngClass]="{'show': !isNavbarCollapsed}" id="navbarSupportedContent1">    
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('nosotros')" [routerLink]="['/inicio']" routerLinkActive="router-link-active">Sobre Nosotros</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO2('/home/1')" [routerLink]="['/home/1']" routerLinkActive="router-link-active">E-commerce</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/catalogo']" routerLinkActive="router-link-active">Catálogo</a>
        </li>
      </ul>
      <!-- <ul class="navbar-nav mr-auto mb-3 " style="padding: 0; margin: 0;">
        <li class="mt-0 mx-3" >
            <div class=" bg-light rounded rounded-pill shadow-sm  divSearch"  >
              <form  class="flex-form " [formGroup]="formSearchProduct">
                <div class="input-group mt-0">
                  <div class="input-group-prepend">
                      <button id="button-addon2" type="submit" class="btn btn-link text-secondary" (click)="searchProduct(formSearchProduct.value)">
                        <i class="fa fa-search"></i>
                      </button>
                  </div>
                  <input type="text" formControlName="text"  placeholder="¿Qué está buscando?"
                      aria-describedby="button-addon2" class="form-control border-0 bg-white rounded-pill">
              </div>
              </form>
            </div>
        </li>
      </ul> -->
    </div>
  </div>
</nav>

<nav *ngIf="idEmpresa==78" class="navbar navbar-expand-lg shadow-sm fixed-top" id="viewSM">
  <div class="container-fluid">
    <form class="form-inline" style="margin-left: 0.5em;" *ngIf="!flagCarLocal">
      <div style="display: flex; justify-content: space-between;">

        <div class="pt-1 divLogin" type="button" (click)="openModal(modalLogin)" *ngIf="!clientLogin.login">
          <a class="nav-link">Iniciar Sesión</a>
        </div>
        <div class="div-auth pt-1" *ngIf="clientLogin.login">
          <i class="bi bi-box-arrow-right icoLogout" type="button" title="SALIR" (click)="signOff()"></i>
          <span class="pr-3 linea-right-blanca spn-user-login" role="button" (click)="goClientProfile()">
            {{clientLogin.name | titlecase}}
          </span>
        </div>
        <!-- <div class="d-flex" style="height: 50px; background-color:rgb(133, 132, 132);">
          <div class="vr" style="width: 1px;"></div>
        </div> -->
        <div class="btn mt-1 ml-1 mr-3 bgCarrito  " style="position: relative; "  type="button"  (click)="goShoppingCartUser()">
          <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;  "  class="badge badge-pill badge-danger">{{counterCar}}</span>
         Mi pedido <i class="bi bi-cart-fill p-1" style=" margin-top: 1em !important; " > </i>  
        </div>
        <!-- <div class="btn mt-1 mr-3" style="position: relative;" type="button" (click)="goShoppingCartUser()">
          <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;" class="badge badge-pill badge-danger">{{cartProducts.number}}</span>
          <i class="fas fa-shopping-cart" [ngStyle]="{ 'color': configuracion?.colorPrincipal }" style="width:6; height:6;"></i>
        </div> -->
      </div>
    </form>
    <button (click)="seeExpand()" class="navbar-toggler" type="button"  aria-controls="navbarSupportedContent">   
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse"   
    [ngClass]="{'show': !isNavbarCollapsed}" id="navbarSupportedContent1">    
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('nosotros')" [routerLink]="['/inicio']" routerLinkActive="router-link-active">Sobre Nosotros</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO2('/home/1')" [routerLink]="['/home/1']" routerLinkActive="router-link-active">E-commerce</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/catalogo']" routerLinkActive="router-link-active">Catálogo</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="router-link-active" (click)="redirect('mision')" [routerLink]="['/inicio']" routerLinkActive="router-link-active"  >Misión</a>
        </li>
        <li class="nav-item">
          <a  class="nav-link" routerLinkActive="router-link-active" (click)="redirect('vision')" [routerLink]="['/inicio']" routerLinkActive="router-link-active"  >Visión</a>
        </li>
      </ul>
      <ul class="navbar-nav mr-auto " style="padding: 0; margin: 0;">
        <li class="mt-0 mx-3" >
            <div class=" bg-light rounded rounded-pill shadow-sm  divSearch"  >
              <form  class="flex-form " [formGroup]="formSearchProduct">
                <div class="input-group mt-0">
                  <div class="input-group-prepend">
                      <button id="button-addon2" type="submit" class="btn btn-link text-secondary" (click)="searchProduct(formSearchProduct.value)">
                        <i class="fa fa-search"></i>
                      </button>
                  </div>
                  <input type="text" formControlName="text"  placeholder="¿Qué está buscando?"
                      aria-describedby="button-addon2" class="form-control border-0 bg-white rounded-pill">
              </div>
              </form>
            </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- <nav class="navbar navbar-expand-lg  rounded-pill shadow fixed-top mt-3 " >
    <a class="navbar-brand p-2" >
      <img  class="" [src]="urlBase+configuracion?.imgLogo+'?alt=media'" style="width: 100%;height: 55px; object-fit: scale-down; ">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active"   >
          <a class="nav-link" (click)="redirecTO('inicio')"     [routerLink]="['/inicio']" routerLinkActive="router-link-active"
           >Inicio <span class="sr-only"></span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('nosotros')" [routerLink]="['/inicio']" routerLinkActive="router-link-active" >Sobre Nosotros</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"   [routerLink]="['/inicio']" routerLinkActive="router-link-active">E-commerce</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('contacto')"   [routerLink]="['/inicio']" routerLinkActive="router-link-active">Contáctanos</a>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <div style="display: flex; justify-content: space-between; " >
          <div class="pt-1" type="button" (click)="openModal(modalLogin)"  *ngIf="!clientLogin.login">
            <a class="nav-link"  >Iniciar Sesión</a>
          </div>
          <div class="div-auth pt-1" *ngIf="clientLogin.login"  >
            <i class="bi bi-box-arrow-right icoLogout"  type="button" title="SALIR" (click)="signOff()" ></i> 
            <span class="pr-3 linea-right-blanca spn-user-login" role="button" (click)="goClientProfile()">
               {{clientLogin.name | titlecase }}
            </span>
        </div>
          <div class="d-flex" style="height: 50px; background-color:rgb(133, 132, 132);  ">
            <div class="vr" style="width: 1px;"> </div>         
        </div>
          <div class="btn mt-1 mr-3" style="position: relative;"  type="button"  (click)="goShoppingCartUser()">
            <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;  "  class="badge badge-pill badge-danger">{{cartProducts.number}}</span>
            <i class="fas fa-shopping-cart" [ngStyle]="{ 'color': configuracion?.colorPrincipal }"
            style="width:6; height:6;"></i>    
          </div>
        </div> 
      </form>
    </div>
</nav> -->

<ng-template #modalLogin class="modal fade" data-backdrop="static" data-keyboard="false">
    <app-login-form [configuracion]="configuracion" [information]="information"  (returnMsjForm)="openNewModal($event)"  ></app-login-form>
</ng-template>
